.info-title{
    color: grey;
}
.div-infocontainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px;
}
.div-infocontainer-disc{
    align-items: baseline;
}
