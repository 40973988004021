body {
  margin: 0;
  background-color: #fafafa !important;
}
/* to remove up&down arrow, if MUI Textfield type=number is used  */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.dt-picker {
  font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    border-radius: 4px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid #ccc;
    /* padding: 10px 12px 10px 12px !important;16px 28px 16px 28px */
    padding: 16px 27px 16px 27px !important;
}

.date-popper {
  z-index: 2;
}

.dt-picker-grid4 {
  font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    border-radius: 4px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid #ccc;
    /* padding: 10px 12px 10px 12px !important;16px 28px 16px 28px */
    padding: 16px 80px 16px 80px !important;
}

.imageuploadbox{
  justify-content:center;
  align-items: center;
  margin-top: 20px;
  height:135px;
  width: 220px;
  border-radius: 13px;
  background: #e3e3e3;
  box-shadow:  15px 20px 41px #c1c1c1,
               -20px -20px 41px #ffffff;
}